export default class StateManager {
  constructor(pstates) {
    this.states = pstates;

    this.onStates = {};
    this.si = 0;
    this.current = this.states[this.si];
  }

  set current(c) {
    if (this._c === c) return;
    this._c = c;

    if (typeof this.onStates[this._c] !== "undefined") {
      this.runCurrent();
    }
  }

  get current() {
    return this._c;
  }

  runCurrent() {
    this.onStates[this._c].forEach((fun) => {
      fun();
    });
  }

  on(name, execute) {
    if (typeof this.onStates[name] === "undefined") this.onStates[name] = [];
    this.onStates[name].push(execute);

    if (this.current === name) {
      this.runCurrent();
    }
  }

  end(name, execute) {
    let x = this.states.indexOf(name) + 1;
    if (x < 0) return;

    name = this.states[x];

    this.on(name, execute);

  }

  progress() {
    this.si++;
    this.current = this.states[this.si];
  }
}

import * as THREE from 'three'

export default class InformationSection
{
    constructor(_options)
    {
        // Options
        this.time = _options.time
        this.resources = _options.resources
        this.objects = _options.objects
        this.areas = _options.areas
        this.tiles = _options.tiles
        this.debug = _options.debug
        this.x = _options.x
        this.y = _options.y

        // Set up
        this.container = new THREE.Object3D()
        this.container.matrixAutoUpdate = false

        this.setStatic()
        // this.setBaguettes()
        // this.setLinks()
        this.setInfoGrafik()
        this.setInfoUiUx()
        this.setInfoFilm()
        this.setInfoCode()
        // this.setTiles()
        // this.setAreasInfo()
    }

    setStatic()
    {
        this.objects.add({
            base: this.resources.items.informationStaticBase.scene,
            collision: this.resources.items.informationStaticCollision.scene,
            floorShadowTexture: this.resources.items.informationStaticFloorShadowTexture,
            offset: new THREE.Vector3(this.x, this.y, 0),
            mass: 0
        })
    }




    setInfoGrafik()
    {
        // Set up
        this.infos = {}
        this.infos.x = this.x -2.5
        this.infos.y = this.y -19.8
        this.infos.multiplier = 6

        // Geometry
        this.infos.geometry = new THREE.PlaneBufferGeometry(1 * this.infos.multiplier, 1 * this.infos.multiplier, 1, 1)

        // Texture
        this.infos.texture = this.resources.items.areasGrafikFloorTexture
        this.infos.texture.magFilter = THREE.NearestFilter
        this.infos.texture.minFilter = THREE.LinearFilter

        // Material
        this.infos.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.infos.texture, transparent: true })

        // Mesh
        this.infos.mesh = new THREE.Mesh(this.infos.geometry, this.infos.material)
        this.infos.mesh.position.x = this.infos.x
        this.infos.mesh.position.y = this.infos.y
        this.infos.mesh.matrixAutoUpdate = false
        this.infos.mesh.updateMatrix()
        this.container.add(this.infos.mesh)
    }

    setInfoUiUx()
    {
        // Set up
        this.infos = {}
        this.infos.x = this.x -2.5
        this.infos.y = this.y + 10
        this.infos.multiplier = 6

        // Geometry
        this.infos.geometry = new THREE.PlaneBufferGeometry(1 * this.infos.multiplier, 1 * this.infos.multiplier, 1, 1)

        // Texture
        this.infos.texture = this.resources.items.areasUiUxFloorTexture
        this.infos.texture.magFilter = THREE.NearestFilter
        this.infos.texture.minFilter = THREE.LinearFilter

        // Material
        this.infos.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.infos.texture, transparent: true })

        // Mesh
        this.infos.mesh = new THREE.Mesh(this.infos.geometry, this.infos.material)
        this.infos.mesh.position.x = this.infos.x
        this.infos.mesh.position.y = this.infos.y
        this.infos.mesh.matrixAutoUpdate = false
        this.infos.mesh.updateMatrix()
        this.container.add(this.infos.mesh)
    }

    setInfoFilm()
    {
        // Set up
        this.infos = {}
        this.infos.x = this.x -2.5
        this.infos.y = this.y - 10
        this.infos.multiplier = 6

        // Geometry
        this.infos.geometry = new THREE.PlaneBufferGeometry(1 * this.infos.multiplier, 1 * this.infos.multiplier, 1, 1)

        // Texture
        this.infos.texture = this.resources.items.areasFilmFloorTexture
        this.infos.texture.magFilter = THREE.NearestFilter
        this.infos.texture.minFilter = THREE.LinearFilter

        // Material
        this.infos.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.infos.texture, transparent: true })

        // Mesh
        this.infos.mesh = new THREE.Mesh(this.infos.geometry, this.infos.material)
        this.infos.mesh.position.x = this.infos.x
        this.infos.mesh.position.y = this.infos.y
        this.infos.mesh.matrixAutoUpdate = false
        this.infos.mesh.updateMatrix()
        this.container.add(this.infos.mesh)
    }

    setInfoCode()
    {
        // Set up
        this.infos = {}
        this.infos.x = this.x -2.5
        this.infos.y = this.y + 0.2
        this.infos.multiplier = 6

        // Geometry
        this.infos.geometry = new THREE.PlaneBufferGeometry(1 * this.infos.multiplier, 1 * this.infos.multiplier, 1, 1)

        // Texture
        this.infos.texture = this.resources.items.areasCodeFloorTexture
        this.infos.texture.magFilter = THREE.NearestFilter
        this.infos.texture.minFilter = THREE.LinearFilter

        // Material
        this.infos.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.infos.texture, transparent: true })

        // Mesh
        this.infos.mesh = new THREE.Mesh(this.infos.geometry, this.infos.material)
        this.infos.mesh.position.x = this.infos.x
        this.infos.mesh.position.y = this.infos.y
        this.infos.mesh.matrixAutoUpdate = false
        this.infos.mesh.updateMatrix()
        this.container.add(this.infos.mesh)
    }

    setTiles()
    {
        this.tiles.add({
            start: new THREE.Vector2(this.x - 1.2, this.y + 13),
            delta: new THREE.Vector2(0, - 20)
        })
    }

    setAreasInfo()
    {
        // Set up
        this.areainfo = {}
        this.areainfo.x = this.x + 0
        this.areainfo.y = this.y - 10
        this.areainfo.multiplier = 6

        // Geometry
        this.areainfo.geometry = new THREE.PlaneBufferGeometry(2 * this.areainfo.multiplier, 1 * this.areainfo.multiplier, 1, 1)

        // Texture
        this.areainfo.texture = this.resources.items.areasCodeFloorTexture
        this.areainfo.texture.magFilter = THREE.NearestFilter
        this.areainfo.texture.minFilter = THREE.LinearFilter

        // Material
        this.areainfo.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.areainfo.texture, transparent: true })

        // Mesh
        this.areainfo.mesh = new THREE.Mesh(this.areainfo.geometry, this.areainfo.material)
        this.areainfo.mesh.position.x = this.areainfo.x
        this.areainfo.mesh.position.y = this.areainfo.y
        this.areainfo.mesh.matrixAutoUpdate = false
        this.areainfo.mesh.updateMatrix()
        this.container.add(this.areainfo.mesh)
    }

}

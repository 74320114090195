import * as THREE from "three";

import text1 from "./assets/vector/text1.svg";

function generateCanvas(width, height) {
  global.genCanvas = global.genCanvas || document.createElement("canvas");
  const canvas = global.genCanvas;

  canvas.width = width;
  canvas.height = height;

  canvas.getContext("2d").clearRect(0, 0, width, height);

  return canvas;
}

const baseSettings = {
  width: window.innerWidth * window.devicePixelRatio,
  height: window.innerHeight * window.devicePixelRatio,
  style: "",
  weight: 600,
  size: 20,
  family: "serif",
  color: "#fff",
  dpr: window.devicePixelRatio || 1,
};

export default function generateTextImage(text, settings = {}) {
  settings = Object.assign({}, baseSettings, settings);

  global.genImg = global.genImg || new Image(settings.width, settings.height);

  const canvas = generateCanvas(settings.width, settings.height, settings.dpr),
    ctx = canvas.getContext("2d"),
    img = global.genImg;

  ctx.scale = settings.dpr;

  ctx.textBaseline = "middle";

  ctx.font =
    settings.style +
    " " +
    settings.weight +
    " " +
    settings.size * settings.dpr +
    "px" +
    " " +
    settings.family;

  ctx.fillStyle = settings.color;
  ctx.textAlign = "center";
  ctx.fillText(
    text,
    Math.round(settings.width / 2),
    Math.round(settings.height / 2),
    settings.width * 0.9
  );

  img.addEventListener("load", () => {
    // Flip Y for WebGL
    ctx.save();
    // ctx.scale(1, -1);
    ctx.clearRect(0, settings.height * -1, settings.width, settings.height);
    ctx.drawImage(
      img,
      0,
      settings.height * -1,
      settings.width,
      settings.height
    );
    ctx.restore();
  });

  img.src = canvas.toDataURL("image/png");

  //   let src = new THREE.TextureLoader().load(text1);

  return new THREE.TextureLoader().load(img.src);
}

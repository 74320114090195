import * as THREE from 'three'

import Loader from './Utils/Loader.js'
import EventEmitter from './Utils/EventEmitter.js'

// Matcaps
import matcapBeigeSource from '../models/matcaps/beige.png'
import matcapBlackSource from '../models/matcaps/black.png'
import matcapOrangeSource from '../models/matcaps/orange.png'
import matcapRedSource from '../models/matcaps/red.png'
import matcapWhiteSource from '../models/matcaps/white.png'
import matcapGreenSource from '../models/matcaps/green.png'
import matcapBrownSource from '../models/matcaps/brown.png'
//Tree
import matcapTreeBrownSource from '../models/matcaps/treeBrown.png'
import matcapTreeGreenSource from '../models/matcaps/treeGreen.png'

import matcapGraySource from '../models/matcaps/gray.png'
import matcapEmeraldGreenSource from '../models/matcaps/emeraldGreen.png'
import matcapPurpleSource from '../models/matcaps/purple.png'
import matcapBlueSource from '../models/matcaps/blue.png'
import matcapYellowSource from '../models/matcaps/yellow.png'
import matcapMetalSource from '../models/matcaps/metal.png'
// import matcapGoldSource from '../models/matcaps/gold.png'

// Intro
import introStaticBaseSource from '../models/intro/static/base.glb'
import introStaticCollisionSource from '../models/intro/static/collision.glb'
import introStaticFloorShadowSource from '../models/intro/static/floorShadow.png'

import introInstructionsLabelsSource from '../models/intro/instructions/labels.glb'
import introInstructionsCatchphraseSource from '../models/intro/instructions/catchphrase.png'
import introInstructionsOtherSource from '../models/intro/instructions/other.png'
import introInstructionsOtherMobileSource from '../models/intro/instructions/otherMobile.png'


import introKBaseSource from '../models/intro/k/base.glb'
import introKCollisionSource from '../models/intro/k/collision.glb'

import introLBaseSource from '../models/intro/l/base.glb'
import introLCollisionSource from '../models/intro/l/collision.glb'

import introIBaseSource from '../models/intro/i/base.glb'
import introICollisionSource from '../models/intro/i/collision.glb'

import introMBaseSource from '../models/intro/m/base.glb'
import introMCollisionSource from '../models/intro/m/collision.glb'

import introPBaseSource from '../models/intro/p/base.glb'
import introPCollisionSource from '../models/intro/p/collision.glb'

import introEBaseSource from '../models/intro/e/base.glb'
import introECollisionSource from '../models/intro/e/collision.glb'

import introRBaseSource from '../models/intro/r/base.glb'
import introRCollisionSource from '../models/intro/r/collision.glb'

// import introIBaseSource from '../models/intro/i/base.glb'
// import introICollisionSource from '../models/intro/i/collision.glb'
//
// import introMBaseSource from '../models/intro/m/base.glb'
// import introMCollisionSource from '../models/intro/m/collision.glb'

import introCreativeBaseSource from '../models/intro/creative/base.glb'
import introCreativeCollisionSource from '../models/intro/creative/collision.glb'

import introDevBaseSource from '../models/intro/dev/base.glb'
import introDevCollisionSource from '../models/intro/dev/collision.glb'

// Crossroads
import crossroadsStaticFloorShadowSource from '../models/crossroads/static/floorShadow.png'
import crossroadsStaticBaseSource from '../models/crossroads/static/base.glb'
import crossroadsStaticCollisionSource from '../models/crossroads/static/collision.glb'

// Car default
import carDefaultChassisSource from '../models/car/default/chassis.glb'
import carDefaultWheelSource from '../models/car/default/wheel.glb'
import carDefaultBackLightsBrakeSource from '../models/car/default/backLightsBrake.glb'
import carDefaultBackLightsReverseSource from '../models/car/default/backLightsReverse.glb'
import carDefaultAntenaSource from '../models/car/default/antena.glb'


// Projects
// import projectsBoardStructureSource from '../models/projects/board/structure.glb'
// import projectsBoardCollisionSource from '../models/projects/board/collision.glb'
// import projectsBoardStructureFloorShadowSource from '../models/projects/board/floorShadow.png'
// import projectsBoardPlaneSource from '../models/projects/board/plane.glb'
//
// import projectsDistinctionsAwwwardsBaseSource from '../models/projects/distinctions/awwwards/base.glb'
// import projectsDistinctionsAwwwardsCollisionSource from '../models/projects/distinctions/awwwards/collision.glb'
// import projectsDistinctionsFWABaseSource from '../models/projects/distinctions/fwa/base.glb'
// import projectsDistinctionsFWACollisionSource from '../models/projects/distinctions/fwa/collision.glb'
// import projectsDistinctionsCSSDABaseSource from '../models/projects/distinctions/cssda/base.glb'
// import projectsDistinctionsCSSDACollisionSource from '../models/projects/distinctions/cssda/collision.glb'

// import infos für Bereiche
import areasCodeFloorSource from '../models/information/static/floorTextureCode.png'
import areasFilmFloorSource from '../models/information/static/floorTextureFilm.png'
import areasUiUxFloorSource from '../models/information/static/floorTextureUiUx.png'
import areasGrafikFloorSource from '../models/information/static/floorTextureGrafik.png'

// import Text für Contact
import contactMailFloorSource from '../models/contact/floorTextureMail.png'
import contactPhoneFloorSource from '../models/contact/floorTexturePhone.png'
import contactGithubFloorSource from '../models/contact/floorTextureGithub.png'

// import projectsCitrixRedbullFloorSource from '../models/projects/citrixRedbull/floorTexture.png'
// import projectsPriorHoldingsFloorSource from '../models/projects/priorHoldings/floorTexture.png'
// import projectsOranoFloorSource from '../models/projects/orano/floorTexture.png'
// import projectsGleecChatFloorSource from '../models/projects/gleecChat/floorTexture.png'
// import projectsRefletCommunicationFloorSource from '../models/projects/refletCommunication/floorTexture.png'
// import projectsKepplerFloorSource from '../models/projects/keppler/floorTexture.png'

// Information
import informationStaticBaseSource from '../models/information/static/base.glb'
import informationStaticCollisionSource from '../models/information/static/collision.glb'
import informationStaticFloorShadowSource from '../models/information/static/floorShadow.png'


// import informationBaguetteBaseSource from '../models/information/baguette/base.glb'
// import informationBaguetteCollisionSource from '../models/information/baguette/collision.glb'

// import informationContactTwitterLabelSource from '../models/information/static/contactTwitterLabel.png'
// import informationContactGithubLabelSource from '../models/information/static/contactGithubLabel.png'
// import informationContactLinkedinLabelSource from '../models/information/static/contactLinkedinLabel.png'
// import informationContactMailLabelSource from '../models/information/static/contactMailLabel.png'


// contact
import contactStaticBaseSource from '../models/contact/base.glb'
import contactStaticCollisionSource from '../models/contact/collision.glb'
import contactStaticFloorShadowSource from '../models/contact/floorShadow.png'

// Playground
import playgroundStaticFloorShadowSource from '../models/playground/static/floorShadow.png'
import playgroundStaticBaseSource from '../models/playground/static/base.glb'
import playgroundStaticCollisionSource from '../models/playground/static/collision.glb'

// Brick
import brickBaseSource from '../models/brick/base.glb'
import brickCollisionSource from '../models/brick/collision.glb'


// // Distinction A
// import distinctionAStaticFloorShadowSource from '../models/distinctionA/static/floorShadow.png'
// import distinctionAStaticBaseSource from '../models/distinctionA/static/base.glb'
// import distinctionAStaticCollisionSource from '../models/distinctionA/static/collision.glb'

// // Distinction B
// import distinctionBStaticFloorShadowSource from '../models/distinctionB/static/floorShadow.png'
// import distinctionBStaticBaseSource from '../models/distinctionB/static/base.glb'
// import distinctionBStaticCollisionSource from '../models/distinctionB/static/collision.glb'

// // Distinction C
// import distinctionCStaticFloorShadowSource from '../models/distinctionC/static/floorShadow.png'
// import distinctionCStaticBaseSource from '../models/distinctionC/static/base.glb'
// import distinctionCStaticCollisionSource from '../models/distinctionC/static/collision.glb'

// // Cone
// import coneBaseSource from '../models/cone/base.glb'
// import coneCollisionSource from '../models/cone/collision.glb'

// // Awwwards trophy
// import awwwardsTrophyBaseSource from '../models/awwwardsTrophy/base.glb'
// import awwwardsTrophyCollisionSource from '../models/awwwardsTrophy/collision.glb'


// Lemon
import lemonBaseSource from '../models/lemon/base.glb'
import lemonCollisionSource from '../models/lemon/collision.glb'


// Area
import areaKeyEnterSource from '../models/area/keyEnter.png'
import areaEnterSource from '../models/area/enter.png'
import areaOpenSource from '../models/area/open.png'
import areaResetSource from '../models/area/reset.png'
import areaQuestionMarkSource from '../models/area/questionMark.png'

// Tiles
// import tilesABaseSource from '../models/tiles/a/base.glb'
// import tilesACollisionSource from '../models/tiles/a/collision.glb'
//
// import tilesBBaseSource from '../models/tiles/b/base.glb'
// import tilesBCollisionSource from '../models/tiles/b/collision.glb'
//
// import tilesCBaseSource from '../models/tiles/c/base.glb'
// import tilesCCollisionSource from '../models/tiles/c/collision.glb'
//
// import tilesDBaseSource from '../models/tiles/d/base.glb'
// import tilesDCollisionSource from '../models/tiles/d/collision.glb'
//
// import tilesEBaseSource from '../models/tiles/e/base.glb'
// import tilesECollisionSource from '../models/tiles/e/collision.glb'


export default class Resources extends EventEmitter
{
    constructor()
    {
        super()

        this.loader = new Loader()
        this.items = {}

        this.loader.load([
            // Matcaps
            { name: 'matcapBeige', source: matcapBeigeSource, type: 'texture' },
            { name: 'matcapBlack', source: matcapBlackSource, type: 'texture' },
            { name: 'matcapOrange', source: matcapOrangeSource, type: 'texture' },
            { name: 'matcapRed', source: matcapRedSource, type: 'texture' },
            { name: 'matcapWhite', source: matcapWhiteSource, type: 'texture' },
            { name: 'matcapGreen', source: matcapGreenSource, type: 'texture' },
            { name: 'matcapBrown', source: matcapBrownSource, type: 'texture' },
            //Tree
            { name: 'matcapTreeGreen', source: matcapTreeGreenSource, type: 'texture' },
            { name: 'matcapTreeBrown', source: matcapTreeBrownSource, type: 'texture' },

            { name: 'matcapGray', source: matcapGraySource, type: 'texture' },
            { name: 'matcapEmeraldGreen', source: matcapEmeraldGreenSource, type: 'texture' },
            { name: 'matcapPurple', source: matcapPurpleSource, type: 'texture' },
            { name: 'matcapBlue', source: matcapBlueSource, type: 'texture' },
            { name: 'matcapYellow', source: matcapYellowSource, type: 'texture' },
            { name: 'matcapMetal', source: matcapMetalSource, type: 'texture' },
            // { name: 'matcapGold', source: matcapGoldSource, type: 'texture' },

            // Intro
            { name: 'introStaticBase', source: introStaticBaseSource },
            { name: 'introStaticCollision', source: introStaticCollisionSource },
            { name: 'introStaticFloorShadow', source: introStaticFloorShadowSource, type: 'texture' },

            { name: 'introInstructionsLabels', source: introInstructionsLabelsSource },
            { name: 'introInstructionsCatchphrase', source: introInstructionsCatchphraseSource, type: 'texture' },
            { name: 'introInstructionsOther', source: introInstructionsOtherSource, type: 'texture' },
            { name: 'introInstructionsOtherMobile', source: introInstructionsOtherMobileSource, type: 'texture' },


            { name: 'introKBase', source: introKBaseSource },
            { name: 'introKCollision', source: introKCollisionSource },

            { name: 'introLBase', source: introLBaseSource },
            { name: 'introLCollision', source: introLCollisionSource },

            { name: 'introIBase', source: introIBaseSource },
            { name: 'introICollision', source: introICollisionSource },

            { name: 'introMBase', source: introMBaseSource },
            { name: 'introMCollision', source: introMCollisionSource },

            { name: 'introPBase', source: introPBaseSource },
            { name: 'introPCollision', source: introPCollisionSource },

            { name: 'introEBase', source: introEBaseSource },
            { name: 'introECollision', source: introECollisionSource },

            { name: 'introRBase', source: introRBaseSource },
            { name: 'introRCollision', source: introRCollisionSource },

            { name: 'introCreativeBase', source: introCreativeBaseSource },
            { name: 'introCreativeCollision', source: introCreativeCollisionSource },

            { name: 'introDevBase', source: introDevBaseSource },
            { name: 'introDevCollision', source: introDevCollisionSource },

            // Intro
            { name: 'crossroadsStaticBase', source: crossroadsStaticBaseSource },
            { name: 'crossroadsStaticCollision', source: crossroadsStaticCollisionSource },
            { name: 'crossroadsStaticFloorShadow', source: crossroadsStaticFloorShadowSource, type: 'texture' },

            // Car default
            { name: 'carDefaultChassis', source: carDefaultChassisSource },
            { name: 'carDefaultWheel', source: carDefaultWheelSource },
            { name: 'carDefaultBackLightsBrake', source: carDefaultBackLightsBrakeSource },
            { name: 'carDefaultBackLightsReverse', source: carDefaultBackLightsReverseSource },
            { name: 'carDefaultAntena', source: carDefaultAntenaSource },


            // // Project
            // { name: 'projectsBoardStructure', source: projectsBoardStructureSource },
            // { name: 'projectsBoardCollision', source: projectsBoardCollisionSource },
            // { name: 'projectsBoardStructureFloorShadow', source: projectsBoardStructureFloorShadowSource, type: 'texture' },
            // { name: 'projectsBoardPlane', source: projectsBoardPlaneSource },
            //
            // { name: 'projectsDistinctionsAwwwardsBase', source: projectsDistinctionsAwwwardsBaseSource },
            // { name: 'projectsDistinctionsAwwwardsCollision', source: projectsDistinctionsAwwwardsCollisionSource },
            // { name: 'projectsDistinctionsFWABase', source: projectsDistinctionsFWABaseSource },
            // { name: 'projectsDistinctionsFWACollision', source: projectsDistinctionsFWACollisionSource },
            // { name: 'projectsDistinctionsCSSDABase', source: projectsDistinctionsCSSDABaseSource },
            // { name: 'projectsDistinctionsCSSDACollision', source: projectsDistinctionsCSSDACollisionSource },

            // Areas Bereiche import
            { name: 'areasCodeFloor', source: areasCodeFloorSource, type: 'texture' },
            { name: 'areasFilmFloor', source: areasFilmFloorSource, type: 'texture' },
            { name: 'areasUiUxFloor', source: areasUiUxFloorSource, type: 'texture' },
            { name: 'areasGrafikFloor', source: areasGrafikFloorSource, type: 'texture' },

            // Contact Text
            { name: 'contactMailFloor', source: contactMailFloorSource, type: 'texture' },
            { name: 'contactGithubFloor', source: contactGithubFloorSource, type: 'texture' },
            { name: 'contactPhoneFloor', source: contactPhoneFloorSource, type: 'texture' },

            // { name: 'projectsCitrixRedbullFloor', source: projectsCitrixRedbullFloorSource, type: 'texture' },
            // { name: 'projectsCitrixRedbullSlideA', source: projectsCitrixRedbullSlideASources, type: 'texture' },
            // { name: 'projectsCitrixRedbullSlideB', source: projectsCitrixRedbullSlideBSources, type: 'texture' },
            // { name: 'projectsCitrixRedbullSlideC', source: projectsCitrixRedbullSlideCSources, type: 'texture' },

            // { name: 'projectsPriorHoldingsFloor', source: projectsPriorHoldingsFloorSource, type: 'texture' },
            // { name: 'projectsPriorHoldingsSlideA', source: projectsPriorHoldingsSlideASources, type: 'texture' },
            // { name: 'projectsPriorHoldingsSlideB', source: projectsPriorHoldingsSlideBSources, type: 'texture' },
            // { name: 'projectsPriorHoldingsSlideC', source: projectsPriorHoldingsSlideCSources, type: 'texture' },

            // { name: 'projectsOranoFloor', source: projectsOranoFloorSource, type: 'texture' },
            // { name: 'projectsOranoSlideA', source: projectsOranoSlideASources, type: 'texture' },
            // { name: 'projectsOranoSlideB', source: projectsOranoSlideBSources, type: 'texture' },
            // { name: 'projectsOranoSlideC', source: projectsOranoSlideCSources, type: 'texture' },

            // { name: 'projectsGleecChatFloor', source: projectsGleecChatFloorSource, type: 'texture' },
            // { name: 'projectsGleecChatSlideA', source: projectsGleecChatSlideASources, type: 'texture' },
            // { name: 'projectsGleecChatSlideB', source: projectsGleecChatSlideBSources, type: 'texture' },
            // { name: 'projectsGleecChatSlideC', source: projectsGleecChatSlideCSources, type: 'texture' },
            // { name: 'projectsGleecChatSlideD', source: projectsGleecChatSlideDSources, type: 'texture' },

            // { name: 'projectsRefletCommunicationFloor', source: projectsRefletCommunicationFloorSource, type: 'texture' },
            // { name: 'projectsRefletCommunicationSlideA', source: projectsRefletCommunicationSlideASources, type: 'texture' },
            // { name: 'projectsRefletCommunicationSlideB', source: projectsRefletCommunicationSlideBSources, type: 'texture' },
            // { name: 'projectsRefletCommunicationSlideC', source: projectsRefletCommunicationSlideCSources, type: 'texture' },
            // { name: 'projectsRefletCommunicationSlideD', source: projectsRefletCommunicationSlideDSources, type: 'texture' },

            // { name: 'projectsKepplerFloor', source: projectsKepplerFloorSource, type: 'texture' },
            // { name: 'projectsKepplerSlideA', source: projectsKepplerSlideASources, type: 'texture' },
            // { name: 'projectsKepplerSlideB', source: projectsKepplerSlideBSources, type: 'texture' },
            // { name: 'projectsKepplerSlideC', source: projectsKepplerSlideCSources, type: 'texture' },

            // Information
            { name: 'informationStaticBase', source: informationStaticBaseSource },
            { name: 'informationStaticCollision', source: informationStaticCollisionSource },
            { name: 'informationStaticFloorShadow', source: informationStaticFloorShadowSource, type: 'texture' },

            // Contact
            { name: 'contactStaticBase', source: contactStaticBaseSource },
            { name: 'contactStaticCollision', source: contactStaticCollisionSource },
            { name: 'contactStaticFloorShadow', source: contactStaticFloorShadowSource, type: 'texture' },

            // { name: 'informationBaguetteBase', source: informationBaguetteBaseSource },
            // { name: 'informationBaguetteCollision', source: informationBaguetteCollisionSource },

            // { name: 'informationContactTwitterLabel', source: informationContactTwitterLabelSource, type: 'texture' },
            // { name: 'informationContactGithubLabel', source: informationContactGithubLabelSource, type: 'texture' },
            // { name: 'informationContactLinkedinLabel', source: informationContactLinkedinLabelSource, type: 'texture' },
            // { name: 'informationContactMailLabel', source: informationContactMailLabelSource, type: 'texture' },


            // Playground
            { name: 'playgroundStaticBase', source: playgroundStaticBaseSource },
            { name: 'playgroundStaticCollision', source: playgroundStaticCollisionSource },
            { name: 'playgroundStaticFloorShadow', source: playgroundStaticFloorShadowSource, type: 'texture' },

            // Brick
            { name: 'brickBase', source: brickBaseSource },
            { name: 'brickCollision', source: brickCollisionSource },


            // // Distinction A
            // { name: 'distinctionAStaticBase', source: distinctionAStaticBaseSource },
            // { name: 'distinctionAStaticCollision', source: distinctionAStaticCollisionSource },
            // { name: 'distinctionAStaticFloorShadow', source: distinctionAStaticFloorShadowSource, type: 'texture' },

            // // Distinction B
            // { name: 'distinctionBStaticBase', source: distinctionBStaticBaseSource },
            // { name: 'distinctionBStaticCollision', source: distinctionBStaticCollisionSource },
            // { name: 'distinctionBStaticFloorShadow', source: distinctionBStaticFloorShadowSource, type: 'texture' },

            // // Distinction C
            // { name: 'distinctionCStaticBase', source: distinctionCStaticBaseSource },
            // { name: 'distinctionCStaticCollision', source: distinctionCStaticCollisionSource },
            // { name: 'distinctionCStaticFloorShadow', source: distinctionCStaticFloorShadowSource, type: 'texture' },

            // // Cone
            // { name: 'coneBase', source: coneBaseSource },
            // { name: 'coneCollision', source: coneCollisionSource },

            // // Awwwards trophy
            // { name: 'awwwardsTrophyBase', source: awwwardsTrophyBaseSource },
            // { name: 'awwwardsTrophyCollision', source: awwwardsTrophyCollisionSource },


            // Lemon
            { name: 'lemonBase', source: lemonBaseSource },
            { name: 'lemonCollision', source: lemonCollisionSource },


            // Areas
            { name: 'areaKeyEnter', source: areaKeyEnterSource, type: 'texture' },
            { name: 'areaEnter', source: areaEnterSource, type: 'texture' },
            { name: 'areaOpen', source: areaOpenSource, type: 'texture' },
            { name: 'areaReset', source: areaResetSource, type: 'texture' },
            { name: 'areaQuestionMark', source: areaQuestionMarkSource, type: 'texture' },

            // Tiles
            // { name: 'tilesABase', source: tilesABaseSource },
            // { name: 'tilesACollision', source: tilesACollisionSource },
            //
            // { name: 'tilesBBase', source: tilesBBaseSource },
            // { name: 'tilesBCollision', source: tilesBCollisionSource },
            //
            // { name: 'tilesCBase', source: tilesCBaseSource },
            // { name: 'tilesCCollision', source: tilesCCollisionSource },
            //
            // { name: 'tilesDBase', source: tilesDBaseSource },
            // { name: 'tilesDCollision', source: tilesDCollisionSource },
            //
            // { name: 'tilesEBase', source: tilesEBaseSource },
            // { name: 'tilesECollision', source: tilesECollisionSource },

        ])

        this.loader.on('fileEnd', (_resource, _data) =>
        {
            this.items[_resource.name] = _data

            // Texture
            if(_resource.type === 'texture')
            {
                const texture = new THREE.Texture(_data)
                texture.needsUpdate = true

                this.items[`${_resource.name}Texture`] = texture
            }

            // Trigger progress
            this.trigger('progress', [this.loader.loaded / this.loader.toLoad])
        })

        this.loader.on('end', () =>
        {
            // Trigger ready
            this.trigger('ready')
        })
    }
}


import Intro from './intro/main.js';

//
//
//
import './style/main.css'
import Application from './javascript/Application.js'


// window.StartGame = ()=> {
  window.application = new Application({
        $canvas: document.querySelector('.js-canvas'),
        useComposer: true
    })

// }


import mainSoundFile from './../static/sound/Main Sound.mp3';

setTimeout(function(){
  let mainSound = new Audio(mainSoundFile);
  mainSound.volume = 0.4
  mainSound.play()
  setInterval(function(){ mainSound.play();

  }, 5000);
}, 2000);


// window.StartGame();

// setTimeout(window.StartGame, 1000)

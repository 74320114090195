import * as THREE from 'three'

export default class ContactSection
{
    constructor(_options)
    {
        // Options
        this.time = _options.time
        this.resources = _options.resources
        this.objects = _options.objects
        this.areas = _options.areas
        this.tiles = _options.tiles
        this.debug = _options.debug
        this.x = _options.x
        this.y = _options.y

        // Set up
        this.container = new THREE.Object3D()
        this.container.matrixAutoUpdate = false

        this.setStatic()
        // this.setBaguettes()
        this.setLinks()
        this.setTextMail()
        this.setTextPhone()
        this.setTextGithub()

        // this.setTiles()
        // this.setAreasInfo()
    }

    setStatic()
    {
        this.objects.add({
            base: this.resources.items.contactStaticBase.scene,
            collision: this.resources.items.contactStaticCollision.scene,
            floorShadowTexture: this.resources.items.contactStaticFloorShadowTexture,
            offset: new THREE.Vector3(this.x, this.y, 0),
            mass: 0
        })
    }



    setLinks()
    {
        // Set up
        this.links = {}
        this.links.x = -2.3
        this.links.y = 9.2
        this.links.halfExtents = {}
        this.links.halfExtents.x = 2
        this.links.halfExtents.y = 0.5
        this.links.distanceBetween = 5.6
        this.links.labelWidth = this.links.halfExtents.x * 2 + 1
        this.links.labelGeometry = new THREE.PlaneBufferGeometry(this.links.labelWidth, this.links.labelWidth * 0.25, 1, 1)
        this.links.labelOffset = - 1.6
        this.links.items = []

        this.links.container = new THREE.Object3D()
        this.links.container.matrixAutoUpdate = false
        this.container.add(this.links.container)

        // Options
        this.links.options = [
            {
              href: 'mailto:janis@klimper.world',
              labelTexture: this.resources.items.informationContactMailLabelTexture
            },
            {
                href: 'tel:0041-79-334-75',
                labelTexture: this.resources.items.informationContactGithubLabelTexture
            },
            {
                href: '/credits.html',
                labelTexture: this.resources.items.informationContactLinkedinLabelTexture
            }
        ]

        // Create each link
        let i = 0
        for(const _option of this.links.options)
        {
            // Set up
            const item = {}
            item.x = this.x + this.links.x + this.links.distanceBetween * i
            item.y = this.y + this.links.y
            item.href = _option.href

            // Create area
            item.area = this.areas.add({
                position: new THREE.Vector2(item.x, item.y),
                halfExtents: new THREE.Vector2(this.links.halfExtents.x, this.links.halfExtents.y)
            })
            item.area.on('interact', () =>
            {
                window.open(_option.href, '_blank')
            })
            item.area.on('overdrive', () =>
            {
              setTimeout(function() {
                  window.open(_option.href, '_blank')
              }, 700);
            })

            // Texture
            // item.texture = _option.labelTexture
            // item.texture.magFilter = THREE.NearestFilter
            // item.texture.minFilter = THREE.LinearFilter

            // Create label
            // item.labelMesh = new THREE.Mesh(this.links.labelGeometry, new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: _option.labelTexture, depthTest: true, depthWrite: false, transparent: true }))
            // item.labelMesh.position.x = item.x + this.links.labelWidth * 0.5 - this.links.halfExtents.x
            // item.labelMesh.position.y = item.y + this.links.labelOffset
            // item.labelMesh.matrixAutoUpdate = false
            // item.labelMesh.updateMatrix()
            // this.links.container.add(item.labelMesh)

            // Save
            this.links.items.push(item)

            i++
        }
    }

    setTextMail()
    {
        // Set up
        this.infos = {}
        this.infos.x = this.x -2.4
        this.infos.y = this.y +6.5
        this.infos.multiplier = 3.5

        // Geometry
        this.infos.geometry = new THREE.PlaneBufferGeometry(2 * this.infos.multiplier, 1 * this.infos.multiplier, 2, 1)

        // Texture
        this.infos.texture = this.resources.items.contactMailFloorTexture
        this.infos.texture.magFilter = THREE.NearestFilter
        this.infos.texture.minFilter = THREE.LinearFilter

        // Material
        this.infos.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.infos.texture, transparent: true })

        // Mesh
        this.infos.mesh = new THREE.Mesh(this.infos.geometry, this.infos.material)
        this.infos.mesh.position.x = this.infos.x
        this.infos.mesh.position.y = this.infos.y
        this.infos.mesh.matrixAutoUpdate = false
        this.infos.mesh.updateMatrix()
        this.container.add(this.infos.mesh)
    }

    setTextGithub()
    {
        // Set up
        this.infos = {}
        this.infos.x = this.x +9.0
        this.infos.y = this.y +6.5
        this.infos.multiplier = 3.5

        // Geometry
        this.infos.geometry = new THREE.PlaneBufferGeometry(2 * this.infos.multiplier, 1 * this.infos.multiplier, 2, 1)

        // Texture
        this.infos.texture = this.resources.items.contactGithubFloorTexture
        this.infos.texture.magFilter = THREE.NearestFilter
        this.infos.texture.minFilter = THREE.LinearFilter

        // Material
        this.infos.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.infos.texture, transparent: true })

        // Mesh
        this.infos.mesh = new THREE.Mesh(this.infos.geometry, this.infos.material)
        this.infos.mesh.position.x = this.infos.x
        this.infos.mesh.position.y = this.infos.y
        this.infos.mesh.matrixAutoUpdate = false
        this.infos.mesh.updateMatrix()
        this.container.add(this.infos.mesh)
    }

    setTextPhone()
    {
        // Set up
        this.infos = {}
        this.infos.x = this.x +3.3
        this.infos.y = this.y +6.5
        this.infos.multiplier = 3.5

        // Geometry
        this.infos.geometry = new THREE.PlaneBufferGeometry(2 * this.infos.multiplier, 1 * this.infos.multiplier, 2, 1)

        // Texture
        this.infos.texture = this.resources.items.contactPhoneFloorTexture
        this.infos.texture.magFilter = THREE.NearestFilter
        this.infos.texture.minFilter = THREE.LinearFilter

        // Material
        this.infos.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.infos.texture, transparent: true })

        // Mesh
        this.infos.mesh = new THREE.Mesh(this.infos.geometry, this.infos.material)
        this.infos.mesh.position.x = this.infos.x
        this.infos.mesh.position.y = this.infos.y
        this.infos.mesh.matrixAutoUpdate = false
        this.infos.mesh.updateMatrix()
        this.container.add(this.infos.mesh)
    }


    setTiles()
    {
        this.tiles.add({
            start: new THREE.Vector2(this.x - 1.2, this.y + 13),
            delta: new THREE.Vector2(0, - 20)
        })
    }

    setAreasInfo()
    {
        // Set up
        this.areainfo = {}
        this.areainfo.x = this.x + 0
        this.areainfo.y = this.y - 10
        this.areainfo.multiplier = 6

        // Geometry
        this.areainfo.geometry = new THREE.PlaneBufferGeometry(2 * this.areainfo.multiplier, 1 * this.areainfo.multiplier, 1, 1)

        // Texture
        this.areainfo.texture = this.resources.items.areasCodeFloorTexture
        this.areainfo.texture.magFilter = THREE.NearestFilter
        this.areainfo.texture.minFilter = THREE.LinearFilter

        // Material
        this.areainfo.material = new THREE.MeshBasicMaterial({ wireframe: false, color: 0xffffff, alphaMap: this.areainfo.texture, transparent: true })

        // Mesh
        this.areainfo.mesh = new THREE.Mesh(this.areainfo.geometry, this.areainfo.material)
        this.areainfo.mesh.position.x = this.areainfo.x
        this.areainfo.mesh.position.y = this.areainfo.y
        this.areainfo.mesh.matrixAutoUpdate = false
        this.areainfo.mesh.updateMatrix()
        this.container.add(this.areainfo.mesh)
    }

}

console.log("asd");

import * as THREE from "three";

import { createMaterial, getAspect } from "./myMaterial";

import StateManager from "./stateManager.js";

import particleTexture from "./assets/sprites/circle.png";

// import particle shaders
import vertex from "./shaders/vertex-particle.glsl";
import fragment from "./shaders/fragment-particle.glsl";

import vertexText from "./shaders/vertex.glsl";
import fragmentText from "./shaders/fragment-distort-text.glsl";



import soundfiletexttrans from './assets/sounds/texttrans.mp3';
import soundfilepop from './assets/sounds/pop.mp3';
import woshfilepop from './assets/sounds/wosh.mp3';
import followfile from './assets/sounds/follow.mp3';
import revealfile from './assets/sounds/reveal.mp3';

let texttrans = new Audio(soundfiletexttrans);
let popsound = new Audio(soundfilepop);
let woshsound = new Audio(woshfilepop);
let followsound = new Audio(followfile);
let revealsound = new Audio(revealfile);


// helper function to create text images
import generateTextImage from "./generateTextImage.js";
const textImageSettings = {
  size: Math.max(20, Math.min(window.innerWidth * 0.1, 100)),
  // family: "sans-serif",
  // color: "red",
};

// setup stats
// import Stats from "stats.js";
// var stats = new Stats();
// stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom

// document.body.appendChild(stats.domElement);

// setup the state manager
// define the states of the intro
const intro = new StateManager([
  "setup",
  "text",
  "text-to-fullscreen",
  "fullscreen",
  "shrink",
  "follow",
  "burst",
]);

let particles, pMaterial, vertices, directions, particleSystem;



// set the root element
const el = document.body;

let scene, camera, clock, renderer;
let geometry, mat, object;

let txtGeometry, txtMat, txtObject;

let handleMouseDown, runAtRender, handleMouseMove;

let bubbleInvincible = true;

let t = 1; // time of shrink animation


/******************
 * SETUP STATE
 ******************/
intro.on("setup", () => {
  scene = new THREE.Scene();
  camera = new THREE.OrthographicCamera(
    el.offsetWidth / -2,
    el.offsetWidth / 2,
    el.offsetHeight / 2,
    el.offsetHeight / -2,
    1,
    1000
  );

  camera.position.z = 1;

  renderer = new THREE.WebGLRenderer({
    antialias: false,
    alpha: true,
  });

  renderer.setPixelRatio(Math.min(2, window.devicePixelRatio));
  renderer.setClearColor(0x000000, 1.0);
  renderer.setSize(el.offsetWidth, el.offsetHeight);
  el.prepend(renderer.domElement);

  clock = new THREE.Clock();

  geometry = new THREE.PlaneBufferGeometry(el.offsetWidth, el.offsetHeight, 1);

  mat = createMaterial(el);
  object = new THREE.Mesh(geometry, mat);
  scene.add(object);

  window.addEventListener("resize", function (e) {
    camera.left = el.offsetWidth / -2;
    camera.right = el.offsetWidth / 2;
    camera.top = el.offsetHeight / 2;
    camera.bottom = el.offsetHeight / -2;

    camera.updateProjectionMatrix();

    renderer.setSize(el.offsetWidth, el.offsetHeight);
  });

  // particle material

  pMaterial = new THREE.ShaderMaterial({
    uniforms: {
      pointTexture: { value: new THREE.TextureLoader().load(particleTexture) },
      size: {
        value: Math.max(5, Math.min((1 / window.innerWidth) * 10000, 30)),
      },
    },
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    opacity: 1.0,
    vertexColors: true,
  });

  // add texts

  txtGeometry = new THREE.PlaneBufferGeometry(
    window.innerWidth,
    window.innerHeight,
    1
  );
  txtMat = new THREE.ShaderMaterial({
    uniforms: {
      map: {
        value: generateTextImage("We will", textImageSettings),
      },
      iTime: { value: 0 },
      iSeed: { value: Math.random() },
      iSpeed: { value: 0.25 },
      iVolatility: { value: 0.1 },
      opacity: { value: 1 },
    },
    vertexShader: vertexText,
    fragmentShader: fragmentText,
    transparent: true,
  });
  // let txtMat = new THREE.MeshBasicMaterial({
  //   map: new THREE.TextureLoader().load(text1),
  //   transparent: true,
  // });
  txtObject = new THREE.Mesh(txtGeometry, txtMat);

  scene.add(txtObject);
  intro.progress();
});

/******************
 * TEXT STATE
 ******************/

let slide = 0,
  oldSlide = 0,
  slideT = 0,
  slideSkip = false,
  // slides = ["we will", "burst your", "bubble"];
  slides = ["Hallo, wir sind Klimper", "das Team mit unkonventionellen Ideen", "und neuartigen Ansätzen.", "Lass uns deine deine Bubble zum Platzen bringen."];
const slideInterval = 4.5; // time to switch slides in secs
const transitionSteps = 50; // steps to transition to new slide

intro.on("text", () => {
  object.visible = false;

  handleMouseDown = (e) => {
    if (e.key) {
      if (!(e.key == " " || e.key == "Enter")) return;
    }

    slideSkip = false;
  };

  document.addEventListener("mousedown", handleMouseDown, true);
  document.addEventListener("keydown", handleMouseDown, true);

  runAtRender = () => {
    txtMat.uniforms.iTime.value = global.elapsed;

    txtMat.uniforms.iVolatility.value = 0.1 + (1 - easeOutCirc(slideT)) * 0.4;
    // txtMat.uniforms.iSpeed.value = 0.1 + easeOutCirc(slideT) * 0.2;
    txtMat.uniforms.opacity.value = easeOutCirc(slideT);

    // update step value
    slideT = Math.min(1, slideT < 1 ? slideT + 1 / transitionSteps : 1);

    if (global.elapsed % slideInterval <= 0.1 || slideSkip) {
      slideSkip = false;
      if (slide == oldSlide) {
        if (slide >= slides.length) {
          intro.progress();
        } else {
          slideT = 0;

          txtMat.uniforms.map.value = generateTextImage(
            slides[slide],
            textImageSettings
          );
          texttrans.play();
          slide++;
        }
      }

      // oldSlide = slide;
    } else {
      oldSlide = slide;
    }
  };

});

intro.end("text", () => {
  global.genImg = null;
  global.genCanvas = null;

  document.removeEventListener("mousedown", handleMouseDown, true);
  document.removeEventListener("keydown", handleMouseDown, true);

});

intro.on("text-to-fullscreen", () => {
  object.visible = true;

  runAtRender = () => {
    txtMat.uniforms.iTime.value = global.elapsed;
    t -= 1 / 50; // steps to fade between text and fullscreen
    mat.uniforms.opacity.value = 1 - t; // fade in
    txtMat.uniforms.opacity.value = t; // fade out
    if (t <= 0) intro.progress();
  };
});

intro.end("text-to-fullscreen", () => {
  runAtRender = null;
  scene.remove(txtObject);
  t = 1;
});

/******************
 * FULLSCREEN STATE
 ******************/
intro.on("fullscreen", () => {
  handleMouseDown = (e) => {
    if (e.key) {
      if (!(e.key == " " || e.key == "Enter")) return;
    }
    intro.progress();
  };

  setTimeout(() => {
    if (intro.current === "fullscreen") {
      intro.progress();
    }
  }, 9000);

  document.addEventListener("mousedown", handleMouseDown, true);
  document.addEventListener("keydown", handleMouseDown, true);
  document.addEventListener("wheel", handleMouseDown, true);
});

/* FULLSCREEN STATE CleanUp */
intro.end("fullscreen", () => {
  document.removeEventListener("mousedown", handleMouseDown, true);
  document.removeEventListener("keydown", handleMouseDown, true);
  document.removeEventListener("wheel", handleMouseDown, true);
});

/******************
 * SHRINK STATE
 ******************/
intro.on("shrink", () => {


  woshsound.play();

  runAtRender = () => {
    if (t > 0) {
      t -= 0.01;
      mat.uniforms.aStep.value = easeInExpo(t);
    } else {
      t = 0;
      intro.progress();
    }
  };
});

/* SHRINK STATE CleanUp */
intro.end("shrink", () => {
  runAtRender = null;
});

/******************
 * FOLLOW STATE
 ******************/

let cursor = {
  x: 0,
  y: 0,
  ix: 0,
  iy: 0,
  dx: 0,
  dy: 0,
};

intro.on("follow", () => {
  // window["StartGame"]()
  document.addEventListener("mousedown", handleMouseDown, true);
  document.addEventListener("keydown", handleMouseDown, true);

  handleMouseMove = ({ clientX, clientY }) => {
    cursor.ix = clientX - el.offsetWidth / 2;
    cursor.iy = el.offsetHeight / 2 - clientY;
    followsound.volume = 0.5;
    followsound.play();
    if (bubbleInvincible) {
      setTimeout(() => {
        bubbleInvincible = false;
      }, 500);
    }
  };

  runAtRender = () => {
    cursor.dx = cursor.x - cursor.ix;
    cursor.dy = cursor.y - cursor.iy;

    cursor.x -= cursor.dx * 0.08;
    cursor.y -= cursor.dy * 0.08;

    mat.uniforms.iDeform.value = Math.min(
      1,
      (1 /
        ((window.innerWidth * window.innerWidth +
          window.innerHeight * window.innerHeight) *
          0.05)) *
        (cursor.dx * cursor.dx + cursor.dy * cursor.dy) *
        (bubbleInvincible ? 0.1 : 1)
    );

    if (mat.uniforms.iDeform.value > 0.9) {
      intro.progress();
    }

    object.position.x = cursor.x;
    object.position.y = cursor.y;
  };

  document.addEventListener("mousemove", handleMouseMove, true);

});

intro.end("follow", () => {
  runAtRender = null;
  document.removeEventListener("mousedown", handleMouseDown, true);
  document.removeEventListener("keydown", handleMouseDown, true);
  document.removeEventListener("mousemove", handleMouseMove, true);
  scene.remove(object);
  geometry.dispose();
  mat.dispose();

});

/******************
 * BURST STATE
 ******************/

const particleCount = 3000;
// const bubbleRadius = window.innerWidth / asp;
const bubbleRadius =
  (Math.min(window.innerWidth, window.innerHeight) * 0.3) / 2;

let pGravity = 0;
const burstSteps = 120; // 60 = 1s

let speed, pDirX, pDirY;

intro.on("burst", () => {
  particles = new THREE.BufferGeometry();
  vertices = [];
  directions = [];

  let pX, pY, pZ, dX, dY, dZ;

  let r, a, p;

  let force;

  let opacities = [];

  for (let i = 0; i < particleCount; i++) {
    // create particle coordinates xy with random values r and t

    // a = random angle
    // r = random radius size

    // a = Math.random() * 2 * Math.PI;
    // r = Math.random() * bubbleRadius;

    // pX = cursor.x + Math.cos(a) * r;
    // pY = cursor.y + Math.sin(a) * r;

    a = Math.random() * 2 * Math.PI;
    p = Math.acos(2 * Math.random() - 1);
    r = bubbleRadius - 2 + Math.random() * 2;

    dX = Math.sin(p) * Math.cos(a);
    dY = Math.sin(a);
    dZ = Math.cos(p);

    pX = cursor.x + r * dX;
    pY = cursor.y + r * Math.sin(p) * dY;
    pZ = -1 + r * dZ;

    vertices.push(pX, pY, pZ);
    opacities.push(1 - Math.random() * 0.5);

    force = Math.random();

    directions.push(dX * force, dY * force, dZ * force);
    popsound.play();

  }

  particles.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(vertices, 3)
  );

  particles.setAttribute(
    "opacity",
    new THREE.Float32BufferAttribute(opacities, 1)
  );

  particles.setAttribute(
    "direction",
    new THREE.Float32BufferAttribute(directions, 3)
  );

  particleSystem = new THREE.Points(particles, pMaterial);

  scene.add(particleSystem);

  t = 1;

  runAtRender = () => {
    const positions = particles.attributes.position;
    const directions = particles.attributes.direction;
    const opacity = particles.attributes.opacity.array;

    if (t > 0) {
      t -= 1 / burstSteps;
    } else {
      intro.progress();
    }

    speed = easeInExpo(t);
    pGravity = Math.min(20, pGravity + 0.1);
    pDirX = cursor.dx * (speed * 0.9 + 0.1) * (5 / burstSteps);
    pDirY = cursor.dy * (speed * 0.9 + 0.1) * (5 / burstSteps) + pGravity;

    for (let i = 0; i < positions.count; i++) {
      const px = positions.getX(i),
        py = positions.getY(i),
        pz = positions.getZ(i);

      const dx = directions.getX(i),
        dy = directions.getY(i),
        dz = directions.getZ(i);

      positions.setXYZ(
        i,
        px + (dx * speed * 40 - pDirX) * Math.random(),
        py + (dy * speed * 40 - pDirY) * Math.random(),
        pz + dz * speed
      );

      opacity[i] -= Math.random() * (1 - easeInOutExpo(t));
    }

    // pMaterial.opacity = t * 0.7;

    positions.needsUpdate = true;
    particles.attributes.opacity.needsUpdate = true;

  };
  window.setTimeout(() =>
  {
  revealsound.play();
}, 1000)
});

intro.end("burst", () => {
  runAtRender = null;

  scene.remove(particleSystem);
  particles.dispose();
  pMaterial.dispose();

  // THIS IS THE END
  //Remove Canvas
  // window["StartGame"]()
  renderer.domElement.remove();

});

const render = () => {
  global.elapsed = clock.getElapsedTime();
  mat.uniforms.iTime.value = global.elapsed;

  // This will be called by the TextureLoader as well as TweenMax.
  renderer.render(scene, camera);

  if (runAtRender) runAtRender();

  // stats.update(); // just there to calculate the fps.

  requestAnimationFrame(render);
};

render();

// timing functions

function easeInOutExpo(x) {
  return x === 0
    ? 0
    : x === 1
    ? 1
    : x < 0.5
    ? Math.pow(2, 20 * x - 10) / 2
    : (2 - Math.pow(2, -20 * x + 10)) / 2;
}

function easeOutCirc(x) {
  return Math.sqrt(1 - Math.pow(x - 1, 2));
}

function easeInCirc(x) {
  return 1 - Math.sqrt(1 - Math.pow(x, 2));
}

function easeInExpo(x) {
  return x === 0 ? 0 : Math.pow(2, 10 * x - 10);
}

import * as THREE from "three";

import vertex from "./shaders/vertex.glsl";
import fragment from "./shaders/fragment-soap-bubble.glsl";
// import reflectionMap from "./assets/reflectionMap.png";

import cubeMap0 from "./assets/simple-cube-map/px.png";
import cubeMap1 from "./assets/simple-cube-map/nx.png";
import cubeMap2 from "./assets/simple-cube-map/py.png";
import cubeMap3 from "./assets/simple-cube-map/ny.png";
import cubeMap4 from "./assets/simple-cube-map/pz.png";
import cubeMap5 from "./assets/simple-cube-map/nz.png";
// import cubeMap0 from "./assets/cubemap-0.png";
// import cubeMap1 from "./assets/cubemap-1.png";
// import cubeMap2 from "./assets/cubemap-2.png";
// import cubeMap3 from "./assets/cubemap-3.png";
// import cubeMap4 from "./assets/cubemap-4.png";
// import cubeMap5 from "./assets/cubemap-5.png";

export function createMaterial(el) {
  const { a1, a2 } = getAspect(el.offsetWidth, el.offsetHeight);

  const mat = new THREE.ShaderMaterial({
    uniforms: {
      // colorB: { type: "vec3", value: new THREE.Color(0xacb6e5) },
      // colorA: { type: "vec3", value: new THREE.Color(0x74ebd5) },
      refMap: {
        type: "t",
        value: new THREE.CubeTextureLoader().load([
          cubeMap0,
          cubeMap1,
          cubeMap2,
          cubeMap3,
          cubeMap4,
          cubeMap5,
        ]),
      },
      iTime: { type: "f", value: 0.0 },
      aStep: { type: "f", value: 1.0 },
      iDeform: { type: "f", value: 0.0 },
      opacity: { type: "f", value: 0 },
      res: {
        type: "vec4",
        value: new THREE.Vector4(el.offsetWidth, el.offsetHeight, a1, a2),
      },
    },

    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    opacity: 1.0,
  });

  return mat;
}

export function getAspect(w, h) {
  // prettier-ignore
  let a2,a1 = a2 = 1;
  if (w > h) {
    a1 = w / h;
  } else {
    a2 = h / w;
  }

  return { a1, a2 };
}
